import React from 'react'
import { Button, ButtonProps } from '@material-ui/core'
import { GatsbyLinkProps, Link } from 'gatsby'

const ForwardLink = React.forwardRef<any, Omit<GatsbyLinkProps<{}>, 'ref'>>((props, ref) => {
  return (
    <Link ref={ref} {...props} />
  )
})

type Props = Omit<ButtonProps<'a', GatsbyLinkProps<{}>>, 'href'>

export default function LinkButton(props: Props) {
  return <Button component={ForwardLink} {...props} />
}
