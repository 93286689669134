import React, { FC, ReactNode } from 'react'
import { AppBar, Box, Container, CssBaseline, Link, Toolbar, Typography } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import { MDXProvider } from '@mdx-js/react'
import assetLinkTheme from 'contexts/assetLinkTheme'
import { graphql, Link as GatsbyLink } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import DebugLoggerConfig from 'components/DebugLoggerConfig'
import ErrorBoundary from 'components/Errors/ErrorBoundary'
import LinkButton from 'components/LinkButton'
import SiteHeadMetadata from 'components/SiteHeadMetadata'

import 'typeface-roboto'

import components from './mdx-components'

import 'global.css'

export type Props = {
  children: ReactNode
  data: {
    mdx: {
      body: string
    }
  }
  pageContext?: {
    slug: string
    frontmatter: {
      title: string
      desc: string
      image: string
    }
  }
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  offset: theme.mixins.toolbar,
  title: {
    flex: '1 0 auto',
    flexGrow: 1,
    maxWidth: 'max-content',
  },
  backLink: {
    flex: '1 0 0%',
    maxWidth: 'max-content',
    whiteSpace: 'nowrap',
  },
  toolbar: {
    width: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}))

export const HelpLayout: FC<Props> = (props: Props) => {
  const {
    data: { mdx },
    pageContext,
  } = props
  const styles = useStyles()

  const title = pageContext?.frontmatter.title || 'AssetLink Help'
  const image = pageContext?.frontmatter.image
  const prefixedImage = image ? 'https://assetlink.testoil.com/' + image : undefined
  const desc = pageContext?.frontmatter.desc
  const slug = pageContext?.slug
  const isHelpIndex = slug === '/help/'

  const additionalMeta = prefixedImage ? [
    {
      name: 'og:image',
      content: prefixedImage,
    },
    {
      name: 'twitter:image',
      content: prefixedImage,
    },
  ] : undefined

  return (
    <ThemeProvider theme={assetLinkTheme}>
      <SiteHeadMetadata title={title} description={desc} meta={additionalMeta} />
      <Container maxWidth="md">
        <CssBaseline />
        <DebugLoggerConfig />
        <ErrorBoundary>
          <AppBar color="primary">
            <Toolbar variant="dense" className={styles.toolbar}>
              {!isHelpIndex ? (
                <Link to="/help" color="inherit" component={GatsbyLink} className={styles.backLink}>← Help Topics</Link>
              ) : (
                <Box flex="1">
                  <Typography>How can we help?</Typography>
                </Box>
              )}
              <LinkButton
                variant="contained"
                size="small"
                className={styles.backLink}
                to="/app/assets"
              >
                Back to App
              </LinkButton>
            </Toolbar>
          </AppBar>
          <div className={styles.offset} />
          <Box my={2}>
            <Typography variant="h2" component="h1">
              {title}
            </Typography>
          </Box>
          <main>
            <MDXProvider components={components}>
              <MDXRenderer>{mdx.body}</MDXRenderer>
            </MDXProvider>
          </main>
        </ErrorBoundary>
      </Container>
    </ThemeProvider>
  )
}

export default HelpLayout

export const pageQuery = graphql`
  query HelpPageQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
    }
  }
`
