/* eslint-disable filenames/match-exported */
import React, { memo } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import MuiTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell, { TableCellProps } from '@material-ui/core/TableCell'
import TableHead, { TableHeadProps } from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import { Link } from 'gatsby'

const useStyles = makeStyles((theme: Theme) => createStyles({
  hr: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  wrapper: {
    paddingBottom: theme.spacing(4),
    '& h2': {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
  },
}))

const components = {
  Link,
  p: (() => {
    const P = (props: any) => <Typography {...props} paragraph />
    return memo(P)
  })(),
  h1: (() => {
    const H1 = (props: any) => {
      return <Typography {...props} component="h1" variant="h1" />
    }
    return memo(H1)
  })(),
  h2: (() => {
    const H2 = (props: any) => <Typography {...props} component="h2" variant="h2" />
    return memo(H2)
  })(),
  h3: (() => {
    const H3 = (props: any) => <Typography {...props} component="h3" variant="h3" />
    return memo(H3)
  })(),
  h4: (() => {
    const H4 = (props: any) => <Typography {...props} component="h4" variant="h4" />
    return memo(H4)
  })(),
  h5: (() => {
    const H5 = (props: any) => <Typography {...props} component="h5" variant="h5" />
    return memo(H5)
  })(),
  h6: (() => {
    const H6 = (props: any) => <Typography {...props} component="h6" variant="h6" />
    return memo(H6)
  })(),
  blockquote: (() => {
    const Blockquote = (props: any) => (
      <Paper style={{ borderLeft: '4px solid grey', padding: 8 }} {...props} />
    )
    return memo(Blockquote)
  })(),
  ul: (() => {
    const Ul = (props: any) => {
      const classes = useStyles()
      return <Typography className={classes.list} {...props} component="ul" />
    }
    return memo(Ul)
  })(),
  ol: (() => {
    const Ol = (props: any) => {
      const classes = useStyles()
      return <Typography className={classes.list} {...props} component="ol" />
    }
    return memo(Ol)
  })(),
  li: (() => {
    const Li = (props: any) => <Typography {...props} component="li" />
    return memo(Li)
  })(),
  table: (() => {
    const Table = (props: any) => <MuiTable {...props} />
    return memo(Table)
  })(),
  tr: (() => {
    const Tr = (props: any) => <TableRow {...props} />
    return memo(Tr)
  })(),
  td: (() => {
    const Td = ({ align, ...props }: TableCellProps) => (
      <TableCell align={align || undefined} {...props} />
    )
    return memo(Td)
  })(),
  tbody: (() => {
    const TBody = (props: any) => <TableBody {...props} />
    return memo(TBody)
  })(),
  th: (() => {
    const Th = ({ align, ...props }: TableCellProps & TableHeadProps) => (
      <TableCell align={align || undefined} {...props} />
    )
    return memo(Th)
  })(),
  thead: (() => {
    const THead = (props: any) => <TableHead {...props} />
    return memo(THead)
  })(),
  hr: (() => {
    const HR = (props: any) => {
      const classes = useStyles()
      return <Divider className={classes.hr} {...props} />
    }
    return memo(HR)
  })(),
  input: (() => {
    const Input = (props: any) => {
      const { type } = props
      if(type === 'checkbox') {
        return <Checkbox {...props} disabled={false} readOnly />
      }
      return <input {...props} />
    }
    return memo(Input)
  })(),
  wrapper: (() => {
    const Wrapper = (props: any) => {
      const classes = useStyles()
      return <div {...props} className={clsx('markdown-body', classes.wrapper)} />
    }
    return memo(Wrapper)
  })(),
}

export default components
